import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  Divider,
  Select,
  OutlinedInput,
  Typography,
  Chip,
  Input,
  Switch,
} from "@mui/material";
import { CalendarToday } from "@mui/icons-material";
import keyicon from "../../assets/svg/SideBar/keyicon.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./generationkey.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  GetElementsSidebar,
  Keygenreation,
} from "../../store/keygenreration/action";
import { Getentrprise, previousLicenseDetails } from "../../store/enterprise/action";
import dayjs from "dayjs";

const GenerateKey = () => {
  const [selectedElements, setSelectedElements] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [numAdmins, setNumAdmins] = useState("");
  const [isPerpetual, setIsPerpetual] = useState(false);
  const [isPerpetual2, setIsPerpetual2] = useState(false);
  const [numEndUsers, setNumEndUsers] = useState("");
  const [numPowerUsers, setNumPowerUsers] = useState("");
  const [ClientName, setClientName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enterpriseslist } = useSelector((state) => state.enterprises);
  const { elementsSidebar } = useSelector((state) => state?.keygenreration);
  const [errors, setErrors] = useState({});
  const [extenalUser, setExtenalUser] = useState(false);

  useEffect(() => {
    dispatch(GetElementsSidebar());
    const payload = { ParentID: null };
    dispatch(Getentrprise(payload));
  }, [dispatch]);

  const handleElementChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = elementsSidebar.data.filter((element) =>
      value.includes(element.ModuleName)
    );
    setSelectedElements(selectedValues);
    validateSelectedElements(selectedValues);
  };

  const validateSelectedElements = (elements) => {
    if (elements.length === 0) {
      setErrors((prev) => ({
        ...prev,
        selectedElements: "At least one element must be selected.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, selectedElements: undefined }));
    }
  };
  const getPreviousDetails = async (EnterpriseID, extendUsers) => {
    const payload = {
      EnterpriseID,
      IsExtendedLicense:!extendUsers
    }
    if (EnterpriseID) {
      const resp = await dispatch(previousLicenseDetails(payload));
      const elements = await elementsSidebar.data.filter((element) =>
        resp?.payload?.ModuleTypeIDs?.includes(element?.ModuleTypeID))
      setSelectedElements(elements);
      if (!extendUsers) {
        const expDate = new Date(resp?.payload?.ValidityTo)
        setNumAdmins(resp?.payload?.NumberOfAdminUsers)
        setNumEndUsers(resp?.payload?.NumberOfEndUsers)
        setNumPowerUsers(resp?.payload?.NumberOfProcessOwnerUsers)
        setStartDate(dayjs(expDate.setDate(expDate.getDate() + 1)))
        setEndDate(null)
        setIsPerpetual(resp?.payload?.PerpetualEndUser)
        setIsPerpetual2(resp?.payload?.PerpetualProcessOwner)
      } else {
        setNumAdmins(resp?.payload?.NumberOfAdminUsers)
        setNumEndUsers(resp?.payload?.NumberOfEndUsers)
        setNumPowerUsers(resp?.payload?.NumberOfProcessOwnerUsers)
        setIsPerpetual(resp?.payload?.PerpetualEndUser)
        setIsPerpetual2(resp?.payload?.PerpetualProcessOwner)
        setStartDate(dayjs(resp?.payload?.ValidityFrom))
        setEndDate(dayjs(resp?.payload?.ValidityTo))
      }
    }
  }
  const handleOrganizationChange = (e) => {
    setClientName(e.target.value);
    getPreviousDetails(e.target.value, extenalUser)
  };
  const handleLicenseTypeChange = (e) => {
    setExtenalUser(e.target.checked)
    getPreviousDetails(ClientName, e.target.checked)
  };

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
    validateForm();
  };

  const validateForm = () => {
    const newErrors = {};

    if (!ClientName) {
      newErrors.ClientName = "Client Name is required.";
    }
    if (!numAdmins) {
      newErrors.numAdmins = "Number of Admins is required.";
    } else if (isNaN(numAdmins) || Number(numAdmins) <= 0) {
      newErrors.numAdmins = "Number of Admins must be a positive number.";
    }
    if (!startDate) {
      newErrors.startDate = "Start Date is required.";
    }
    if (!endDate) {
      newErrors.endDate = "End Date is required.";
    } else if (startDate && endDate && endDate.isBefore(startDate)) {
      newErrors.endDate = "End Date must be after Start Date.";
    }
    if (!(isPerpetual || isPerpetual2)) {
      if (!numEndUsers) {
        newErrors.numEndUsers = "Number of End Users is required.";
      } else if (isNaN(numEndUsers) || Number(numEndUsers) < 0) {
        newErrors.numEndUsers =
          "Number of End Users must be a non-negative number.";
      }
    }
    if (!isPerpetual2) {
      if (
        numPowerUsers &&
        (isNaN(numPowerUsers) || Number(numPowerUsers) < 0)
      ) {
        newErrors.numPowerUsers =
          "Number of Power Users must be a non-negative number.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClick = async () => {
    if (!validateForm()) {
      return;
    }

    const moduleIds = selectedElements.map((element) => element.ModuleTypeID);
    const payload = {
      EnterpriseID: ClientName,
      ModuleTypeIDs: moduleIds,
      NumberOfEndUsers: Number(numEndUsers),
      NumberOfProcessOwnerUsers: Number(numPowerUsers),
      PerpetualProcessOwner: isPerpetual2,
      PerpetualEndUser: isPerpetual,
      NumberOfAdminUsers: Number(numAdmins),
      ValidityFrom: startDate.format("YYYY-MM-DD"),
      ValidityTo: endDate.format("YYYY-MM-DD"),
      MacInterFaces: enterpriseslist.find((el) => el.EnterpriseID === ClientName)?.MacInterFaces,
      IsExtendedLicense: !extenalUser
    };

    try {
      const res = await dispatch(Keygenreation(payload));
      if (res) {
        navigate(`/key`, {
          state: { OrganizationStructureID: ClientName, ...res },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="generationkey-container">
      <div className="heading">
        <img src={keyicon} alt="" className="headerimg" />
        <div>
          <Typography variant="h6">Generate Key</Typography>
          <Typography className="title">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit...
          </Typography>
        </div>
      </div>
      <Divider style={{ margin: "20px 0" }} />
      <div>
        <FormControlLabel
          control={<Switch value={extenalUser} onChange={handleLicenseTypeChange} />} label="Update License" />
      </div>
      <div>
        <Typography className="input-label" variant="body2">
          Client Name
        </Typography>
        <Select
          onChange={handleOrganizationChange}
          input={<OutlinedInput />}
          fullWidth
          className="element-select"
          displayEmpty
          error={!!errors.ClientName}
          helperText={errors.ClientName}
        >
          {enterpriseslist?.length > 0 &&
            enterpriseslist.map((item) => (
              <MenuItem
                key={item.EnterpriseID}
                value={item.EnterpriseID}
              >
                <Typography variant="body1">
                  {item?.EnterpriseName}
                </Typography>
              </MenuItem>
            ))}
        </Select>
      </div>
      <div className="flex-container">
        <div style={{ flex: 1 }}>
          <Typography className="input-label" variant="body2">
            Element Type
          </Typography>
          <Select
            multiple
            value={selectedElements?.map((el) => el.ModuleName)}
            onChange={handleElementChange}
            input={<OutlinedInput />}
            renderValue={(selected) =>
              selected.length === 0 ? "Select elements" : selected.join(", ")
            }
            fullWidth
            className="element-select"
            displayEmpty
            error={!!errors.selectedElements}
            helperText={errors.selectedElements}
          >
            <MenuItem disabled value="">
              <Typography variant="body1">Select elements</Typography>
            </MenuItem>
            {elementsSidebar?.data?.map((element) => (
              <MenuItem key={element.ModuleName} value={element?.ModuleName}>
                {element.ModuleName}
              </MenuItem>
            ))}
          </Select>
          <div className="chip-container">
            {selectedElements.map((element) => (
              <Chip
                key={element.ModuleTypeID}
                label={element.ModuleName}
                onDelete={() => {
                  setSelectedElements((prev) =>
                    prev.filter(
                      (el) => el.ModuleTypeID !== element.ModuleTypeID
                    )
                  );
                  validateSelectedElements(
                    selectedElements.filter(
                      (el) => el.ModuleTypeID !== element.ModuleTypeID
                    )
                  );
                }}
                style={{ backgroundColor: "#F2F4F7", color: "#101828" }}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          marginTop: "20px",
          flexWrap: "wrap",
        }}
      >
        <div style={{ flex: "1 1 30%", minWidth: "280px" }}>
          <Typography className="input-label" variant="body2">
            Number of End Users
          </Typography>
          <Input
            type="number"
            placeholder="Enter number of end users"
            style={{
              width: "100%",
              height: "48px",
              padding: "10px",
              borderRadius: "8px",
              border: "1px solid #E2E8F0",
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            error={!!errors.numEndUsers}
            helperText={errors.numEndUsers}
            onChange={handleChange(setNumEndUsers)}
            value={numEndUsers}
            disabled={isPerpetual}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isPerpetual}
                onChange={() => setIsPerpetual(!isPerpetual)}
              />
            }
            label="Perpetual"
          />
        </div>
        <div style={{ flex: "1 1 30%", minWidth: "280px" }}>
          <Typography className="input-label" variant="body2">
            Number of Power Users
          </Typography>
          <Input
            type="number"
            placeholder="Enter number of power users"
            style={{
              width: "100%",
              height: "48px",
              padding: "10px",
              borderRadius: "8px",
              border: "1px solid #E2E8F0",
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            onChange={handleChange(setNumPowerUsers)}
            error={!!errors.numPowerUsers}
            helperText={errors.numPowerUsers}
            disabled={isPerpetual2}
            value={numPowerUsers}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isPerpetual2}
                onChange={() => setIsPerpetual2(!isPerpetual2)}
              />
            }
            label="Perpetual"
          />
        </div>
        <div style={{ flex: "1 1 30%", minWidth: "280px" }}>
          <Typography className="input-label" variant="body2">
            Number of Administrators
          </Typography>
          <Select
            value={numAdmins}
            onChange={handleChange(setNumAdmins)}
            displayEmpty
            fullWidth
            style={{
              height: "48px",
              borderRadius: "8px",
              border: "1px solid #E2E8F0",
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            error={!!errors.numAdmins}
            helperText={errors.numAdmins}
          >
            <MenuItem value="">
              <Typography variant="body2">
                Select number of administrators
              </Typography>
            </MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </div>
      </div>
      <div className="flex-container">
        <div style={{ flex: 1 }}>
          <Typography className="input-label" variant="body2">
            Choose Start Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={startDate}
              disabled={extenalUser}
              onChange={(newValue) => {
                setStartDate(newValue);
                validateForm();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.startDate}
                  helperText={errors.startDate}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <CalendarToday style={{ marginRight: "10px" }} />
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ flex: 1 }}>
          <Typography className="input-label" variant="body2">
            Choose End Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={endDate}
              disabled={extenalUser}
              onChange={(newValue) => {
                setEndDate(newValue);
                validateForm();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.endDate}
                  helperText={errors.endDate}
                  InputProps={{
                    endAdornment: (
                      <CalendarToday style={{ marginRight: "10px" }} />
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="centered-button">
        <Button
          variant="contained"
          onClick={handleClick}
          className="generate-button"
        >
          Generate Key
        </Button>
      </div>
    </div>
  );
};

export default GenerateKey;
