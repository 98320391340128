import axios from 'axios';
import React, { useEffect } from 'react'

export default function DashboardComponent() {
    const handleDownload = async () => {
        // try {
        //     const response = await axios.post(
        //         'http://localhost:4321/v1/P7Y4Y/ZxaYxShD6Ei0yW9',
        //         {}, // Replace with your file list
        //         {
        //             headers: {
        //                 'Authorization': 'Bearer '+localStorage.getItem('access_token'), // Replace with your access token
        //             },
        //             responseType: 'blob', // Important to handle binary data
        //         }
        //     );

        //     // Create a Blob URL for the file and initiate download
        //     const blob = new Blob([response.data], { type: 'application/zip' });
        //     const url = window.URL.createObjectURL(blob);

        //     // Create a temporary link element
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', 'assets.zip'); // Filename for download
        //     document.body.appendChild(link);
        //     link.click();

        //     // Clean up
        //     link.remove();
        //     window.URL.revokeObjectURL(url);
        // } catch (error) {
        //     console.error('Error downloading the zip file:', error.message);
        // }
    };

    useEffect(() => {
        console.log('DashboardComponent rendered')
        handleDownload()
    }, [])
  return (
    <div>DashboardComponent</div>
  )
}
